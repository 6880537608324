const trackAttr = 'data-track';

const trackEvent = (category, action, label) => {

  const obj = {
    event: 'Tracking',
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  };

  window.dataLayer.push(obj);

  return obj;

}

const initExternalEvents = () => {

  const $buttons = document.querySelectorAll('[rel="external"],[rel="external noopener"]');

  $buttons.forEach(($button) => {

    const href = $button.getAttribute('href') || $button.getAttribute('data-href');
    const internal = $button.getAttribute(trackAttr);

    if (!href || internal) return false;

    $button.addEventListener('click', (e) => trackEvent('External Link', 'Click', href));

  });

  return $buttons;

}

const initCustomEvents = () => {

  const $buttons = document.querySelectorAll(`[${trackAttr}]`);

  $buttons.forEach(($button) => {

    $button.addEventListener('click', (e) => {

      const string = $button.getAttribute(trackAttr);
      const json = string.replace(/'/g, '"');
      const obj = JSON.parse(json);

      window.dataLayer.push(obj);

    });

  });

  return $buttons;

}

const init = () => {

  window.dataLayer = window.dataLayer || [];

  initExternalEvents();
  initCustomEvents();

  return window.dataLayer;

}

export {
  init,
  trackEvent
};
