import { detectFontSwap } from './global/utils';
import setScrollbar from './global/viewport';
import initCookie from './global/cookie';
import initLazyload from './global/lazyload';
import initHover from './global/hover';
import initForms from './global/forms';
import { init as initNav } from './global/nav';
import initSearch from './global/search';
import initSticky from './global/header';
import initScroll from './global/scroll';
import initPseudo from './global/pseudo';
import { init as initTracking } from './global/tracking';

const init = () => {

  window.addEventListener('DOMContentLoaded', () => {

    detectFontSwap();
    setScrollbar();

    initCookie();
    initLazyload();
    initHover();
    initNav();
    initForms();
    initSearch();
    initSticky();
    initScroll();
    initPseudo();
    initTracking();

    if (document.querySelector('[data-stories-grid]')) {
      import('./components/stories').then(module => module.init());
    }

    if (document.querySelector('[data-target="filter-menu"]')) {
      import('./components/filters').then(module => module.init());
    }

    if (document.querySelector('[data-origin]')) {
      import('./components/origin').then(module => module.init());
    }

    if (document.querySelector('[data-timeline]')) {
      import('./components/timeline').then(module => module.init());
    }

    if (document.querySelector('[data-slider]')) {
      import('./components/slider').then(module => module.init());
    }

    if (document.querySelector('[data-video-play]')) {
      import('./components/video').then(module => module.init());
    }

    if (document.querySelector('[data-banner]')) {
      import('./components/banner').then(module => module.init());
    }

    if (document.querySelector('[data-tab-button]')) {
      import('./components/tabbed').then(module => module.init());
    }

    if (document.querySelector('[data-product]')) {
      import('./components/parallax').then(module => module.init());
    }

    if (document.querySelector('[data-exit-popup]')) {
      import('./components/exit').then(module => module.init());
    }

  });

}

init();
