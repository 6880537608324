import Blazy from 'blazy';
import { checkAutoplay } from './utils';

const init = () => {

  const bLazy = new Blazy({

    success: ($element) => {

      const autoplay = $element.getAttribute('data-autoplay');

      if (autoplay) checkAutoplay($element);

    }

  });

  return bLazy;

}

export default init;
