import { gsap } from 'gsap';
import { debounce, throttle, setCSSVar, getElementHeight } from './utils';

let headerHeight = 0;
let alreadyBound = false;

const toggleStickyHeader = (headerTween, buttonTween) => {

  const needToShow = (window.scrollY > headerHeight);

  if (needToShow) {

    headerTween.play();
    buttonTween.play();

  } else {

    headerTween.reverse();
    buttonTween.reverse();

  }

  return needToShow;

}

const bindScroll = (headerTween, buttonTween) => window.addEventListener('scroll', throttle(() => toggleStickyHeader(headerTween, buttonTween), 10), { passive: true });

const bindStickyHeader = () => {

  const $global = document.querySelector('[data-global-header]');
  const $sticky = document.querySelector('[data-sticky-header]');
  const stickyHeight = getElementHeight($sticky);
  const $button = document.querySelector('.icon[data-back-to-top]');

  const settings = {
    duration: 0.4,
    ease: 'power2.out',
    paused: true,
    reversed: true
  }

  headerHeight = getElementHeight($global);
  setCSSVar('header', stickyHeight);

  const headerTween = gsap.to($sticky, {
    y: 0,
    ...settings
  });

  const buttonTween = gsap.to($button, {
    autoAlpha: 1,
    ...settings
  });

  toggleStickyHeader(headerTween, buttonTween);

  if (alreadyBound) return false;

  alreadyBound = true;

  bindScroll(headerTween, buttonTween);

  return headerHeight;

}

const bindResize = () => {

  const reset = debounce((e) => bindStickyHeader(), 100);

  window.addEventListener('resize', reset);
  window.addEventListener('orientationchange', reset);

  return reset;

}

const init = () => {

  bindStickyHeader();
  bindResize();

}

export default init;
