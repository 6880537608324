import { gsap } from 'gsap';
import { aboveBreakpoint, fadeElement } from './utils';

const showSearchBar = ($search, tween) => {

  $search.classList.add('nav__search--active');

  tween.play();

  return $search;

}

const bindSearchShow = ($search, $clear, tween) => {

  const $button = document.querySelector('[data-search-show]');

  $button.addEventListener('click', (e) => showSearchBar($search, tween));

  return $button;

}

const bindSearchInput = ($input, $clear) => {

  $input.addEventListener('input', (e) => {

    (e.target.value)
      ? fadeElement($clear, false)
      : fadeElement($clear, true, 0.3, 'power2', true);

      return e.target.value;

  });

}

const bindSearchClear = ($form, $input, $clear, tween) => {

  $clear.addEventListener('click', (e) => {

    const landscapeViewport = aboveBreakpoint('landscape');

    $input.value = '';
    fadeElement($clear, true, 0.3, 'power2', true);

    $form.classList.remove('nav__search--active', 'results__search--active');

    if (tween && !tween.reversed() && landscapeViewport) tween.duration(0.3).reverse();

    return $input.value;

  });

}

const bindSearch = () => {

  const $forms = document.querySelectorAll('[data-search]');

  $forms.forEach(($form, i) => {

    const $input = $form.querySelector('input[type="text"]');
    const $clear = $form.querySelector('[data-search-clear]');

    bindSearchInput($input, $clear);

    if (i === 0) {

      const tween = gsap.to($form, {
        display: 'block',
        width: '100%',
        duration: 0.3,
        ease: 'power2',
        paused: true,
        reversed: true,
        onReverseComplete: () => gsap.set($form, { clearProps: 'display,width' })
      });

      bindSearchClear($form, $input, $clear, tween);
      bindSearchShow($form, $clear, tween);

    } else {

      bindSearchClear($form, $input, $clear);

    }

    return $form;

  });

}

const init = () => {

  bindSearch();

}

export default init;
