import { setCSSVar } from './utils';

const setScrollbar = () => setCSSVar('sb', (window.innerWidth - document.body.offsetWidth));

const init = () => {

  setScrollbar();

}

export default init;
