const hrefAttr = 'data-href';

const initPseudoButtons = () => {

  const $buttons = document.querySelectorAll(`a [${hrefAttr}]`);

  $buttons.forEach(($button) => {

    $button.addEventListener('click', (e) => {

      e.stopPropagation();
      e.preventDefault();

      const href = e.target.getAttribute(hrefAttr);
      const target = (e.target.getAttribute('target') && e.target.getAttribute('target') === '_blank') ? '_blank' : '_self';

      window.open(href, target);

      return href;

    });

  });

  return $buttons;

}

const init = () => {

  initPseudoButtons();

}

export default init;
