import { gsap } from 'gsap';

let isFontDisplaySupported = false;

const detectFontSwap = () => {

  try {

    let e = document.createElement('style');

    e.textContent = '@font-face { font-display: swap; }';
    document.documentElement.appendChild(e);

    isFontDisplaySupported = e.sheet.cssRules[0].cssText.indexOf('font-display') != -1;

    e.remove();

  } catch (ex) {

    console.log('error: ' + ex);

  }

  fontsLoaded();

}

const fontsLoaded = () => {

  if (isFontDisplaySupported === false && 'fonts' in document) {

    document.fonts.ready.then((fontFaceSet) => {

      document.documentElement.classList.add('fonts');

    });

  } else {

    document.documentElement.classList.add('fonts');

  }

}

const debounce = (func, delay) => {

  let timerId;

  return function (...args) {

    if (timerId) {

      clearTimeout(timerId);

    }

    timerId = setTimeout(() => {

      func(...args);

      timerId = null;

    }, delay);

  }

}

const throttle = (func, delay) => {

  let lastCall = 0;

  return function (...args) {

    const now = (new Date).getTime();

    if (now - lastCall < delay) {

      return;

    }

    lastCall = now;

    return func(...args);

  }

}

const toggleClasses = (target, classes) => {

  const $targets = document.querySelectorAll(`[data-target="${target}"]`);

  if (target.includes('menu')) document.body.classList.toggle(classes);

  $targets.forEach($target => $target.classList.toggle(classes));

}

const bindClassToggle = (target, classes, cb) => {

  return new Promise((resolve) => {

    let $button = document.querySelector(`[data-toggle="${target}"]`),
        $targets = document.querySelectorAll(`[data-target="${target}"]`);

    $button.addEventListener('click', (e) => {

      $targets.forEach($target => {

        $target.classList.toggle(classes);

      });

      if (cb) cb();

    });

    resolve($button);

  });

}

const bindScroll = ($target, block = 'start') => {

  $target.scrollIntoView({ behavior: 'smooth', block: block });

  return $target;

}

const bindScrollTargets = () => {

  let $btns = document.querySelectorAll(`[data-scroll-target]`);

  $btns.forEach(($btn) => {

    $btn.addEventListener('click', (e) => {

      e.preventDefault();

      let target = $btn.getAttribute('data-scroll-target'),
          $target = document.querySelector(target);

      if ($target) bindScroll($target);

      return $target;

    });

  });

}

const setHeightVar = ($target, $element, varName) => {

  let height = $target.scrollHeight;

  if ($target && $element) $element.style.setProperty(varName, height);

  return height;

}

const usingTouchScreen = () => {

  let supportsTouch = false;

  if (('ontouchstart' in window) ||
    window.DocumentTouch && document instanceof DocumentTouch ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0) {

    supportsTouch = true;

  }

  return supportsTouch;

}

const getClosest = (elem, selector) => {

  for ( ; elem && elem !== document; elem = elem.parentNode ) {

    if (elem.matches(selector)) return elem;

  }

  return null;

}

const classAdd = ($elem, string) => {

  $elem.classList.add(string);

  return $elem;

}

const classRemove = ($elem, string) => {

  $elem.classList.remove(string);

  return $elem;

}

const getCSSVar = (name, $target) => {

  const $elem = ($target) ? $target : document.body;
  const styles = getComputedStyle($elem);

  return styles.getPropertyValue(`--${name}`);

}

const setCSSVar = (key, value, $target) => {

  const $elem = ($target) ? $target : document.body;

  $elem.style.setProperty(`--${key}`, value);

  return value;

}

const aboveBreakpoint = (breakpoint) => {

  return window.matchMedia(`(min-width: ${getCSSVar(breakpoint)})`).matches;

}

const fadeElement = ($element, hide, duration = 0.3, ease = 'power2', clearProps = false) => {

  const animate = (hide) ? 0 : 1;
  const clear = (clearProps) ? 'all' : '';

  gsap.to($element, {
    autoAlpha: animate,
    duration: duration,
    ease: ease,
    clearProps: clear
  });

  return animate;

}

const emptyElement = ($element) => {

  while ($element.firstChild) $element.removeChild($element.firstChild);

  return $element;

}

const removeElement = ($element) => $element.parentNode.removeChild($element);

const getElementWidth = ($element) => {

  const list = [
      // 'margin-left',
      // 'margin-right',
      // 'border-left',
      // 'border-right',
      // 'padding-left',
      // 'padding-right',
      'width'
  ];

  const style = window.getComputedStyle($element);

  let width = list
                .map(key => parseInt(style.getPropertyValue(key), 10))
                .reduce((prev, cur) => prev + cur);

  if (isNaN(width)) width = 0;

  return width;

}

const getElementHeight = ($element) => {

  const list = [
      // 'margin-top',
      // 'margin-bottom',
      // 'border-top',
      // 'border-bottom',
      // 'padding-top',
      // 'padding-bottom',
      'height'
  ];

  const style = window.getComputedStyle($element);

  let height = list
                 .map(key => parseInt(style.getPropertyValue(key), 10))
                 .reduce((prev, cur) => prev + cur);

  if (isNaN(height)) height = 0;

  return height;

}

// const getPercentage = (getPercent, ofNumber) => (Math.round((getPercent / ofNumber * 100) * 100) / 100);

const getPercentage = (getPercent, ofNumber) => (getPercent / ofNumber * 100);

const serialize = ($inputs) => {

  // Setup our serialized data
	var serialized = [];

	// Loop through each field in the form
	for (var i = 0; i < $inputs.length; i++) {

		var field = $inputs[i];

		// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
		if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

		// If a multi-select, get all selections
		if (field.type === 'select-multiple') {
			for (var n = 0; n < field.options.length; n++) {
				if (!field.options[n].selected) continue;
				serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
			}
		}

		// Convert field data to a query string
		else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
			serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
		}
	}

	return serialized.join('&');

}

const getTextDirection = () => (document.documentElement.getAttribute('dir') === 'rtl') ? 'rtl' : 'ltr';

const getViewportDimensions = () => ({ width: window.innerWidth, height: window.innerHeight });

const registerPlugins = (plugins) => {

  return new Promise((resolve) => {

    plugins.forEach((plugin, i) => gsap.registerPlugin(plugin));

    resolve(true);

  });

}

const checkAutoplay = ($target) => {

  const promise = $target.play();

  if (promise !== undefined) {

    promise.then((resolve, reject) => {

      $target.classList.add('background__video--playing');

    }).catch(error => {

      console.log(error);

    });

  }

  return promise;

}

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i=0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export {
  detectFontSwap,
  debounce,
  throttle,
  toggleClasses,
  bindClassToggle,
  bindScroll,
  bindScrollTargets,
  setHeightVar,
  usingTouchScreen,
  getClosest,
  classAdd,
  classRemove,
  getCSSVar,
  setCSSVar,
  aboveBreakpoint,
  fadeElement,
  emptyElement,
  removeElement,
  getElementWidth,
  getElementHeight,
  getPercentage,
  serialize,
  getTextDirection,
  getViewportDimensions,
  registerPlugins,
  checkAutoplay,
  setCookie,
  getCookie
};
