import CC from 'CookieConsent';
import delay from './delay';
import { emptyElement, fadeElement } from './utils';

const init = () => {

  const $cookie = document.querySelector('[data-cookie-banner]');

  emptyElement($cookie);

  window.cookieconsent.initialise({
    container: $cookie,
    position: 'bottom-left',
    type: 'opt-in',
    theme: 'classic',
    domain: $cookie.getAttribute('data-cookie-banner-domain'),
    expiryDays: 30,
    secure: true,
    onInitialise: function(status) {
      if (this.hasConsented()) {
        window.dataLayer.push({ 'event': 'cookiesConsented' });
      }
    },
    onStatusChange: function(status) {
      if (this.hasConsented()) {
        window.dataLayer.push({ 'event': 'cookiesConsented' });
      }
    },
    content: {
      header: $cookie.getAttribute('data-cookie-banner-heading-text'),
      message: $cookie.getAttribute('data-cookie-banner-message'),
      dismiss: $cookie.getAttribute('data-cookie-banner-button-text'),
      allow: $cookie.getAttribute('data-cookie-banner-allow-text'),
      deny: $cookie.getAttribute('data-cookie-banner-decline-text'),
      link: $cookie.getAttribute('data-cookie-banner-more-text'),
      href: $cookie.getAttribute('data-cookie-banner-more-url'),
      close: '&#x274c;',
      policy: $cookie.getAttribute('data-cookie-banner-cookie-policy-text'),
      target: '_self'
    },
    onPopupOpen: () => delay(500).then(() => fadeElement($cookie, false, 0.4, 'power2.out')),
    onPopupClose: () => fadeElement($cookie, true, 0.15, 'power2.out')
  });

  return true;

};

export default init;
