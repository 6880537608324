import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { registerPlugins } from './utils';

const initScroll = () => {

  const $buttons = document.querySelectorAll('[data-back-to-top]');

  $buttons.forEach(($button) => {

    $button.addEventListener('click', (e) => {

      e.preventDefault();

       gsap.to(window, { scrollTo: { y: 0 } });

      return $button;

    });

  });

  return $buttons;

}

const init = () => {

  registerPlugins([ScrollToPlugin]).then(() => {

    initScroll();

  });

}

export default init;
