let needAnimFrame = true,
    pos = { x: 0, y: 0 },
    $target;

const calcVars = (e) => {

  let rect = e.currentTarget.getBoundingClientRect(),
      posX = (e.clientX - rect.left),
      posY = (e.clientY - rect.top),
      percentX = ((posX / rect.width) * 200) - 100,
      percentY = ((posY / rect.height) * 200) - 100,
      x = (percentX * 0.05).toFixed(2),
      y = (percentY * 0.05).toFixed(2);

  pos.x = x * -1;
  pos.y = y * -1;

  return pos;

}

const updateVars = () => {

  needAnimFrame = true;

  $target.style.setProperty('--x', pos.x);
  $target.style.setProperty('--y', pos.y);

  return needAnimFrame;

}

const init = () => {

  let $blocks = document.querySelectorAll('[data-hover]');

  $blocks.forEach($block => {

    $block.onmousemove = (e) => {

      $target = $block;

      calcVars(e);

      if (needAnimFrame) {

        needAnimFrame = false;
        requestAnimationFrame(updateVars);

        return needAnimFrame;

      }

    }

  });

}

export default init;
