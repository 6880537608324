import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import CustomEase from './../vendor/gsap-ease';
import { toggleClasses, aboveBreakpoint, registerPlugins } from './utils';

const settings = { duration: 0.2, ease: 'power2' };

const scrollToDropdown = ($country, reverse) => {

  const $wrapper = document.querySelector('[data-target="main-menu"]');
  const offset = $wrapper.scrollTop;
  const height = (reverse) ? $country.offsetHeight : -Math.abs($country.offsetHeight);
  const scroll = offset + height;

  gsap.to($wrapper, { ...settings, scrollTo: { y: scroll } });

  return height;

}

const bindDropdown = () => {

  const $button = document.querySelector('[data-toggle="country-menu"]');
  const $country = document.querySelector('[data-country-selector]');

  if (!$button || !$country) return false;

  const dropdown = { ...settings, display: 'none', transform: 'scale(0)', opacity: 0.001 };

  let reversed = true;

  $button.addEventListener('click', (e) => {

    const largeViewport = aboveBreakpoint('nav');

    toggleClasses('country-menu', 'nav--active--country');

    if (reversed) {

      const display = (largeViewport) ? 'block' : 'grid';

      gsap.set($country, { display: display, transform: 'scale(1)', opacity: 0.999 });

      if (!largeViewport) scrollToDropdown($country, reversed);

      gsap.from($country, { ...dropdown });

      reversed = false;

    } else {

      if (!largeViewport) scrollToDropdown($country, reversed);

      gsap.to($country, { ...dropdown });

      reversed = true;

    }

    return reversed;

  });

}

const scrollToTop = () => gsap.to(window, { ...settings, scrollTo: { y: 0 } });

const bindNav = (target, type) => {

  const $buttons = document.querySelectorAll(`[data-toggle="${target}"]`);
  const $nav = document.querySelector(`[data-target="${target}"]`);

  const tween = gsap.to($nav, {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    duration: 0.4,
    ease: CustomEase.create('custom', '0.83, 0, 0.17, 1'),
    paused: true,
    reversed: true,
    onReverseComplete: () => {

      gsap.set($nav, { clearProps: 'clip-path' });
      toggleClasses(target, `nav--active--${type}`);

    }
  });

  $buttons.forEach(($button) => {

    $button.addEventListener('click', (e) => {

      scrollToTop();

      if (tween.reversed()) {

        toggleClasses(target, `nav--active--${type}`);
        tween.play();

      } else {

        tween.reverse();

      }

      return tween.reversed();

    });

  });

}

const init = () => {

  registerPlugins([CustomEase, ScrollToPlugin]).then(() => {

    bindNav('main-menu', 'mobile');
    bindDropdown();

  });

}

export {
  bindNav,
  init
};
