const bindUpload = ($upload) => {

  const $input = $upload.querySelector('[data-form-upload-input]');
  const $name = $upload.querySelector('[data-form-upload-name]');

  if (!$input || !$name) return false;

  $input.addEventListener('change', (e) => {

    if ($input.files.length) {

      const fileName = $input.files[0].name;

      $name.textContent = fileName;

    }

  });

  return $input;

}

const bindDropdown = ($dropdown) => {

  const $parent = $dropdown.querySelector('[data-form-select-parent]');
  const $children = $dropdown.querySelectorAll('[data-form-select-child]');
  const activeClass = 'form-input--select__child--active';

  if (!$parent) return false;

  $parent.addEventListener('change', (e) => {

    const value = e.target.value;
    const $target = $dropdown.querySelector(`[data-form-select-child="${value}"]`);

    $children.forEach(($child, i) => $child.classList.remove(activeClass));

    if ($target) $target.classList.add(activeClass);

    return $target;

  });

  return $parent;

}

const bindForm = ($form) => {

  const $dropdowns = $form.querySelectorAll('[data-form-select]');
  const $uploads = $form.querySelectorAll('[data-form-upload]');

  $dropdowns.forEach(($dropdown, i) => bindDropdown($dropdown));
  $uploads.forEach(($upload, i) => bindUpload($upload));

  return $dropdowns;

}

const bindForms = () => {

  const $forms = document.querySelectorAll('[data-form]');

  $forms.forEach(($form, i) => bindForm($form));

  return $forms;

}

const init = () => {

  bindForms();

}

export default init;
